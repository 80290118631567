$('#search_autre').autocomplete({
    serviceUrl: '/produit/recherche',
    type: 'POST',
    dataType: 'json',
    params: {'_token': $('#search_token').val(), 'classement_id': 3},
    minChars: 4,
    width: 600,
    onSelect: function (suggestion) {
        //window.location.replace("/produit/fiche/" + suggestion.data);
        if (suggestion.type == 'vin') {
            window.location.replace("/produit/fiche/" + suggestion.data);
        } else if (suggestion.type == 'appellation') {
            window.location.replace("/appellation/" + suggestion.data);
        } else if (suggestion.type == 'propriete') {
            window.location.replace("/propriete/" + suggestion.data);
        }
    }
});

$('#search_grand').autocomplete({
    serviceUrl: '/produit/recherche',
    type: 'POST',
    dataType: 'json',
    params: {'_token': $('#search_token').val(), 'classement_id': 2},
    minChars: 4,
    width: 600,
    onSelect: function (suggestion) {
        //window.location.replace("/produit/fiche/" + suggestion.data);
        if (suggestion.type == 'vin') {
            window.location.replace("/produit/fiche/" + suggestion.data);
        } else if (suggestion.type == 'appellation') {
            window.location.replace("/appellation/" + suggestion.data);
        } else if (suggestion.type == 'propriete') {
            window.location.replace("/propriete/" + suggestion.data);
        }
    }
});

$('#search_premier').autocomplete({
    serviceUrl: '/produit/recherche',
    type: 'POST',
    dataType: 'json',
    params: {'_token': $('#search_token').val(), 'classement_id': 1},
    minChars: 4,
    width: 600,
    onSelect: function (suggestion) {
        //window.location.replace("/produit/fiche/" + suggestion.data);
        if (suggestion.type == 'vin') {
            window.location.replace("/produit/fiche/" + suggestion.data);
        } else if (suggestion.type == 'appellation') {
            window.location.replace("/appellation/" + suggestion.data);
        } else if (suggestion.type == 'propriete') {
            window.location.replace("/propriete/" + suggestion.data);
        }
    }
});
// mes envies search
$('#envies-search-input').autocomplete({
    serviceUrl: '/produit/rechercheProduit',
    type: 'POST',
    dataType: 'json',
    params: {'_token': $('#search_token').val()},
    minChars: 4,
    width: 600,
    onSelect: function (suggestion) {
        //console.log(suggestion);
        $(this).val(suggestion.value);
        $('#addEnvies').val(suggestion.id);
    }
});